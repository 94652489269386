<template>
  <quick-quote-page
    id="Billing"
    :optionsname="$options.name"
  >
    <template #content>
      <billing-table
        ref="maintenanceBillingTable"
      />
    </template>
  </quick-quote-page>
</template>

<script>
  import QuickQuotePage from '@/components/QuickQuotePage'
  import BillingTable from '@/components/maintenance/tables/BillingTable'

  export default {
    name: 'BillingView',

    components: {
      QuickQuotePage,
      BillingTable,
    },
  }
</script>

<style scoped>

</style>
